<template>
  <div class="order-search">
    <div>
      <el-table border style="width: 100%" :data="onlineServiceList" v-loading="loading">
        <el-table-column prop="uid" label="客服ID" align="center"></el-table-column>
        <el-table-column prop="order_price" label="头像" align="center">
          <template slot-scope="{row}">
            <el-avatar size="large" :src="row.headImgUrl">
              <i class="el-icon-user-solid" style="font-size: 20px"></i>
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="昵称" align="center"></el-table-column>
        <el-table-column prop="processingCount" label="支付中(订单数量)" align="center"></el-table-column>
        <el-table-column prop="onLine" label="状态" align="center">
          在线
        </el-table-column>
        <el-table-column prop="lastOperationTime" label="最后操作时间" align="center">
          <template slot-scope="{row}">
            {{row.lastOperationTime?timeFormat(Number(row.lastOperationTime / 1000), 'yyyy-mm-dd hh:MM:ss'):'-'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{row}">
            <el-button type="text" @click="changeOnlineStatus(row)">下线</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getonline, offline} from "@/api/code";
import timeFormat from "@/utils/timeFormat"

export default {
  data(){
    return {
      onlineServiceList:[],
      loading:false,
    }
  },
  mounted() {
    this.getOnlineService()
  },
  methods:{
    timeFormat,
    async getOnlineService(){
      try{
        this.loading = true
        const res = await getonline({onLine: 1, isWork: 1})
        this.onlineServiceList = res.data
        this.loading = false
      }catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    changeOnlineStatus(row){
      this.$confirm('确定操作该客服下线?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try{
          const res = await offline({uid: row.uid})
          if(res.code === 0){
            this.loading = true
            setTimeout(async ()=>{
              await this.getOnlineService()
              this.$message.success(res.msg)
              this.loading = false
            },2000)
          }
        }catch (e) {
          console.log(e)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
